//
// Reboot
// --------------------------------------------------

// Import Google Fonts (Roboto)
@import url($path-to-fonts);

html * {
  @include font-smoothing;
}

// Links
a:focus { outline: none; }
a { transition: $link-transition; }

// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg { max-width: 100%; }

// Responsive iframes
iframe {
  width: 100%;
  border: 0;
}

// Horizontal rule
hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $border-color;
}

// Text Selection Color
::selection {
  background: rgba($accent, .15);
}
::-moz-selection {
  background: rgba($accent, .15);
}

// Change Bootstrap default .container-fluid paddings
@include media-breakpoint-up(lg) {
  .container-fluid {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

small { font-weight: inherit; }

// Remove top padding from body when screen size below md (992px)
@include media-breakpoint-down(md) {
  body { padding-top: 0 !important; }
}

// Disable button :focus outline
button:focus {
  outline: none;
}
