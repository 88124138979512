//
// Utilities
// Extends default Bootstrap utility classes
// Classes with a purpose to reduce the frequency of 
// highly repetitive declarations
// --------------------------------------------------


// Disabled background of the element
.bg-0 {
  background: none !important;
}

// Override .bg-secondary
.bg-secondary {
  background-color: $gray-100 !important;
}

// Add .bg-darker
.bg-darker {
  background-color: $gray-900 !important;
}

// Faded background color
.bg-faded-primary {
  background-color: rgba($primary, .1) !important;
}
.bg-faded-accent {
  background-color: rgba($accent, .1) !important;
}
.bg-faded-success {
  background-color: rgba($success, .1) !important;
}
.bg-faded-danger {
  background-color: rgba($danger, .08) !important;
}
.bg-faded-warning {
  background-color: rgba($warning, .1) !important;
}
.bg-faded-info {
  background-color: rgba($info, .1) !important;
}
.bg-faded-dark {
  background-color: rgba($dark, .1) !important;
}
.bg-faded-light {
  background-color: rgba($white, .1) !important;
}

// Background image specific utilities
.bg-size-cover {
  background-size: cover !important;
}
.bg-position-center {
  background-position: center !important;
}
.bg-position-center-y {
  background-position-y: center !important;
}
.bg-position-center-x {
  background-position-x: center !important;
}
.bg-position-right-top {
  background-position: right top !important;
}
.bg-position-left-top {
  background-position: left top !important;
}
.bg-position-center-top {
  background-position: center top !important;
}
.bg-position-right-center {
  background-position: right center !important;
}
.bg-position-left-center {
  background-position: left center !important;
}
.bg-no-repeat {
  background-repeat: no-repeat !important;
}
.bg-repeat-x {
  background-repeat: repeat-x !important;
}
.bg-repeat-y {
  background-repeat: repeat-y !important;
}

// Overlay
.bg-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $black;
  opacity: .5;
  z-index: 1;
}
.bg-overlay-content {
  display: block;
  position: relative;
  z-index: 5;
}

// Added .font-weight-medium class to default set of Bootstrap .font-weight- classes
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

// Body font size variants: .font-size-{lg}
@each $size, $value in $font-sizes {
  @include font-size-variant(".font-size-#{$size}", $value);
}

// Text shadow
.text-shadow {
  text-shadow: $text-shadow !important;
}

// Headings color
.text-heading {
  color: $headings-color !important;
}

// Border color: for textual dividers
.text-border {
  color: darken($border-color, 8%) !important;
  font-weight: $font-weight-light;
}

// Box shadow
.box-shadow {
  @include box-shadow($box-shadow !important);
}
.box-shadow-sm {
  @include box-shadow($box-shadow-sm !important);
}
.box-shadow-lg {
  @include box-shadow($box-shadow-lg !important);
}

// Add additional rounded corner sizes
.rounded-lg {
  border-radius: $border-radius-lg;
}
.rounded-sm {
  border-radius: $border-radius-sm;
}

// Disable border-radius
.rounded-top-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

// Disable box-shadow for specific component
.box-shadow-0 {
  box-shadow: none !important;
}

// Disable text decoration
.text-decoration-0 {
  text-decoration: none !important;
}

// Additional spacing that matches the grid gutter
@each $prop, $abbrev in (margin: m, padding: p) {
  .#{$abbrev}t-grid-gutter,
  .#{$abbrev}y-grid-gutter {
    #{$prop}-top: $grid-gutter-width !important;
  }
  .#{$abbrev}r-grid-gutter,
  .#{$abbrev}x-grid-gutter {
    #{$prop}-right: $grid-gutter-width !important;
  }
  .#{$abbrev}b-grid-gutter,
  .#{$abbrev}y-grid-gutter {
    #{$prop}-bottom: $grid-gutter-width !important;
  }
  .#{$abbrev}l-grid-gutter,
  .#{$abbrev}x-grid-gutter {
    #{$prop}-left: $grid-gutter-width !important;
  }
}

// Override border-light color
.border-light {
  border-color: $border-light-color !important;
}

// Opacity classes
.opacity-25 {
  opacity: .25 !important;
}
.opacity-50 {
  opacity: .5 !important;
}
.opacity-60 {
  opacity: .6 !important;
}
.opacity-70 {
  opacity: .70 !important;
}
.opacity-75 {
  opacity: .75 !important;
}
.opacity-80 {
  opacity: .8 !important;
}
.opacity-90 {
  opacity: .9 !important;
}
.opacity-100 {
  opacity: 1 !important;
}

// Hide filter list items
.cz-filter-item.d-none { display: none !important; }

// Full height iframe
.iframe-full-height-wrap {
  min-height: 18rem;
  overflow: hidden;
}
.iframe-full-height {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Responsive text wrapping
@include media-breakpoint-up(xl) {
  .text-xl-nowrap { white-space: nowrap !important; }
  .text-xl-wrap { white-space: wrap !important; }
}
@include media-breakpoint-up(lg) {
  .text-lg-nowrap { white-space: nowrap !important; }
  .text-lg-wrap { white-space: wrap !important; }
}
@include media-breakpoint-up(md) {
  .text-md-nowrap { white-space: nowrap !important; }
  .text-md-wrap { white-space: wrap !important; }
}
@include media-breakpoint-up(sm) {
  .text-sm-nowrap { white-space: nowrap !important; }
  .text-sm-wrap { white-space: wrap !important; }
}

// Apply base line height
.line-height-base {
  line-height: $line-height-base !important;
}

// Table layout fixed
.table-fixed { table-layout: fixed !important; }

// Disable animation
.animation-0 { animation: none !important; }

// IE fix for Media component inside flex container
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  .media-ie-fix .media-body {
    flex: 0 1 auto;
  }
}
