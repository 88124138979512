// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

$white:                 #fff;
$gray-100:              #f6f9fc;
$gray-200:              #f3f5f9;
$gray-300:              #e3e9ef;
$gray-400:              #dae1e7;
$gray-500:              #aeb4be;
$gray-600:              #7d879c;
$gray-700:            	#4b566b;
$gray-800:              #373f50;
$gray-900:              #2b3445;
$black:                 #000;

$primary:               #d0983c;
$accent:                #528078;
$secondary:             $gray-200;
$info:		              #355351;
$success:               #74a84a;
$warning:               #f19812;
$danger:                #f44336;
$light:                 $white;
$dark:                  $gray-800;

$light-primary:         #f0f3ee;
$light-secondary:       #a7ad8c;
$light-accent:          #80bebb;
$dark-primary:          #355351;
$dark-secondary:        #233231;
$dark-accent:           #b14e36;

$brand-primary:         #496b33;
$guild-rangers:         #577b38;
$guild-wilders:         #665938;
$guild-mariners:        #243d5a;
$guild-artisans:        #cb4532;

$theme-colors: (
  "accent": $accent,
  "light-primary": $light-primary,
  "light-secondary": $light-secondary,
  "light-accent": $light-accent,
  "dark-primary": $dark-primary,
  "dark-secondary": $dark-secondary,
  "dark-accent": $dark-accent,
  "brand-primary": $brand-primary,
  "guild-rangers": $guild-rangers,
  "guild-wilders": $guild-wilders,
  "guild-mariners": $guild-mariners,
  "guild-artisans": $guild-artisans
);
